export const statements = {
	sublinks: [
		{
			heading: 'Popular Statement Examples',
			param: '',
			links: [
				{
					title: 'Accounting',
					value: '/personal-statement-examples/accounting/',
				},
				{
					title: 'Biology',
					value: '/personal-statement-examples/biology',
				},
				{
					title: 'Business Studies',
					value: '/personal-statement-examples/business-studies',
				},
				{
					title: 'Computer Science',
					value: '/personal-statement-examples/computer-science',
				},

				{
					title: 'Economics',
					value: '/personal-statement-examples/economics',
				},
				{
					title: 'Engineering',
					value: '/personal-statement-examples/engineering',
				},
				{
					title: 'English Literature',
					value: '/personal-statement-examples/english-literature',
				},
				{
					title: 'History',
					value: '/personal-statement-examples/history',
				},

				{ title: 'Law', value: '/personal-statement-examples/law' },
				{ title: 'Maths', value: '/personal-statement-examples/maths' },
				{
					title: 'Medicine',
					value: '/personal-statement-examples/medicine',
				},
				{
					title: 'Nursing',
					value: '/personal-statement-examples/nursing',
				},

				{
					title: 'Psychology',
					value: '/personal-statement-examples/psychology',
				},
				{
					title: 'Pharmacology',
					value: '/personal-statement-examples/pharmacology',
				},
				{
					title: 'Physiotherapy',
					value: '/personal-statement-examples/physiotherapy',
				},
				{
					title: 'Sociology',
					value: '/personal-statement-examples/sociology',
				},
			],
		},
	],
	furtherInformation: [
		{
			image: '/content/images/Article--7jUziilvpRMXkpZ.jpg',
			title: 'What to include in a Personal Statement',
			description: '',
			href: '/advice/personal-statement/what-to-include-in-a-personal-statement/',
			linkText: 'Read Article',
			external: false,
		},
		{
			image: '/content/images/Article--xMJAqlXP9jcN4LO.jpg',
			title: 'Personal Statement Tips',
			description: '',
			href: '/advice/personal-statement/personal-statement-tips/',
			linkText: 'Read Article',
			external: false,
		},
	],
	browse: '/personal-statement-examples',
};
