<a
	[routerLink]="data.href"
	(click)="closeNav()"
	class="relative hidden w-full lg:block h-36"
>
	<img [ngSrc]="data.image | cdn" alt="" class="nav-image" fill />
</a>
<h2 class="lg:mt-6">
	<a [routerLink]="data.href" (click)="closeNav()" class="navlink-black">{{
		data.title
	}}</a>
</h2>
<p class="mt-1 mb-3 nav-description">
	{{ data.description }}
</p>
<a [routerLink]="data.href" (click)="closeNav()" class="navlink-blue">
	{{ data.linkText }}
	<uc-arrow-right-v3-svg></uc-arrow-right-v3-svg>
</a>
