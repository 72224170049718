<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-2 lg:container">
		<!-- left panel -->
		<div class="grid lg:grid-cols-2 lg:pt-8 lg:pb-11 lg:grid lg:gap-9">
			<div>
				<p class="subnav-heading">{{ data.hubLinks.undergraduate.title }}</p>
				<div class="grid subnav-link-padding">
					@for (link of data.hubLinks.undergraduate.links; track link) {
						<uc-nav-link [linkData]="link" />
					}
				</div>
			</div>
			<div>
				<p class="subnav-heading">{{ data.hubLinks.postgraduate.title }}</p>
				<div class="grid subnav-link-padding">
					@for (link of data.hubLinks.postgraduate.links; track link) {
						<uc-nav-link [linkData]="link" />
					}
				</div>
			</div>
		</div>

		<!-- divider -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		<div
			class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:col-span-1 lg:pb-11 bg-gray-50 side-style"
		>
			<p class="subnav-heading">Not sure where to start?</p>
			<uc-nav-card-landscape [data]="data.mainLinks.undergraduate" />
			<uc-nav-card-landscape [data]="data.mainLinks.postgraduate" />
		</div>
	</div>
</div>
