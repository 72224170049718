import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	withLatestFrom,
	distinctUntilChanged,
	filter,
	switchMap,
	from,
	map,
	catchError,
	of,
} from 'rxjs';
import {
	fetchRecommendedUnis,
	updateRecommendedUnis,
	loadNavError,
	fetchAdviceArticles,
	updateAdviceArticles,
	fetchClearingUnis,
	updateClearingUnis,
	fetchClearingArticles,
	updateClearingArticles,
	fetchTickertape,
	updateTickertape,
} from './navigation.actions';
import {
	getStateNavArticles,
	getStateNavClearingArticles,
	getStateNavClearingUnis,
	getStateNavTickertape,
	getStateNavUnis,
} from './navigation.selectors';

import { NavigationService } from '../navigation-api.service';
import { ProductEnum } from '@uc/web/shared/data-models';

import {
	formatUnisData,
	formatArticlesData,
	formatClearingArticlesData,
} from '../../utils/navigation.utils';
import { isArrayEmpty } from '@uc/utilities';

@Injectable()
export class StateNavigationEffects {
	constructor(
		private readonly _actions$: Actions,
		private _store: Store,
		private _navigationSrv: NavigationService,
	) {}

	fetchRecommendedUnis = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchRecommendedUnis),
			withLatestFrom(this._store.select(getStateNavUnis)),
			distinctUntilChanged(),
			filter(([, unis]) => unis.length === 0),
			switchMap(() =>
				from(this._navigationSrv.getRecommendedUnis()).pipe(
					map((response) => {
						const recommendedUnis = formatUnisData(
							response,
							ProductEnum.Undergraduate,
						);
						return updateRecommendedUnis({
							unis: recommendedUnis,
						});
					}),
					catchError((error) => of(loadNavError(error))),
				),
			),
		),
	);

	fetchAdviceArticles = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchAdviceArticles),
			withLatestFrom(this._store.select(getStateNavArticles)),
			distinctUntilChanged(),
			filter(([, articles]) => articles.length === 0),
			switchMap(() =>
				from(this._navigationSrv.getFeaturedArticle()).pipe(
					map((response) => {
						const recommendedArticles = formatArticlesData(
							response.editors_choice,
						);
						return updateAdviceArticles({
							articles: recommendedArticles,
						});
					}),
					catchError((error) => of(loadNavError({ error: error }))),
				),
			),
		),
	);

	fetchClearingUnis = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchClearingUnis),
			withLatestFrom(this._store.select(getStateNavClearingUnis)),
			distinctUntilChanged(),
			filter(([, clearingUnis]) => clearingUnis.length === 0),
			switchMap(() =>
				from(this._navigationSrv.getClearingRecommendedUnis()).pipe(
					map((response) => {
						const cleUnis = formatUnisData(response, ProductEnum.Clearing);
						return updateClearingUnis({
							clearingUnis: cleUnis,
						});
					}),
					catchError((error) => of(loadNavError(error))),
				),
			),
		),
	);

	fetchClearingArticles = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchClearingArticles),
			withLatestFrom(this._store.select(getStateNavClearingArticles)),
			distinctUntilChanged(),
			filter(([, clearingArticles]) => clearingArticles.length === 0),
			switchMap(() =>
				from(this._navigationSrv.getAllArticles()).pipe(
					map((response) => {
						const clearingArticles = formatClearingArticlesData(
							response['UCAS'],
							response['A_Levels'],
						);
						return updateClearingArticles({
							clearingArticles: clearingArticles,
						});
					}),
					catchError((error) => of(loadNavError({ error: error }))),
				),
			),
		),
	);

	fetchTickertape = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchTickertape),
			withLatestFrom(this._store.select(getStateNavTickertape)),
			distinctUntilChanged(),
			filter(([, tickertape]) => isArrayEmpty(tickertape)),
			switchMap(() =>
				from(this._navigationSrv.getTickertapeData()).pipe(
					map((tickertape) => updateTickertape({ tickertape })),
					catchError((error) => of(loadNavError({ error: error }))),
				),
			),
		),
	);
}
