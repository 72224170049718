import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LoginGTMService, RegisterGTMService } from '@uc/web/core';
import { BaseNavlinkComponent } from '../base-navlink.component';

@Component({
	selector: 'uc-avatar-user-menu',
	templateUrl: './avatar-user-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarUserMenuComponent extends BaseNavlinkComponent {
	userAuthenticated = input.required<boolean>();
	clientAuthenticated = input.required<boolean>();
	avatar = input.required<string | undefined>();
	logo = input.required<string | undefined>();

	private readonly _componentName = 'Nav';

	constructor(
		private loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
	) {
		super();
	}

	onLoginClick = () => this.loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
}
