import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import {
	DegreeQuizGTMService,
	DownloadAppGTMService,
	ProspectusGTMService,
	RequestInfoGTMService,
	LoginGTMService,
	RegisterGTMService,
} from '@uc/web/core';

@Component({
	selector: 'uc-secondary-nav-mobile',
	templateUrl: './secondary-nav-mobile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryNavMobileComponent extends BaseNavlinkComponent {
	userAuthenticated = input.required<boolean>();
	clientAuthenticated = input.required<boolean>();
	avatar = input.required<string | undefined>();
	firstName = input.required<string | undefined>();
	secondName = input.required<string | undefined>();
	startYear = input.required<number | undefined>();
	degree = input.required<string | undefined>();
	logo = input.required<string | undefined>();
	uniId = input.required<number | undefined>();
	uniName = input.required<string | undefined>();

	private readonly _componentName = 'Nav';

	constructor(
		private _loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
		private _prospectusGTMService: ProspectusGTMService,
		private _downloadAppGTMService: DownloadAppGTMService,
		private _degreeQuizGTMSrv: DegreeQuizGTMService,
		private _requestInfoGTMService: RequestInfoGTMService,
	) {
		super();
	}

	onLoginClick = () => this._loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
	onProspectusClick = () =>
		this._prospectusGTMService.getProspectusClick(this._componentName, null);
	onDownloadAppClick = () => this._downloadAppGTMService.downloadAppClick();
	onQuizButtonClick = () => this._degreeQuizGTMSrv.onQuizButtonClick('default');
	onRequestInfoClick = () =>
		this._requestInfoGTMService.requestInfoClick(this._componentName, null);
}
