<!-- secondary nav desktop & tablet -->
<uc-secondary-nav-desktop
	[userAuthenticated]="userAuthenticated"
	[clientAuthenticated]="clientAuthenticated"
/>

<!-- primary nav -->
<nav aria-label="Primary" class="border-b border-gray-200 relative bg-white z-[102]">
	<div class="container">
		<div class="relative flex items-center justify-between h-18">
			<uc-site-logo
				[expandedNav]="expandedNav"
				[isMobileShowing]="isMobileNavShowing"
			/>

			<uc-course-finder
				[resetSearchTerm]="true"
				[navigate]="true"
				[resetErrorOnBlur]="true"
			/>

			<!-- Nav links - Desktop screen -->
			@if (!showMobile) {
				<div
					class="justify-center flex-1 hidden h-full px-2 mr-4 lg:flex lg:justify-end"
				>
					<div class="-my-px lg:flex lg:ml-6 lg:space-x-5 xl:space-x-8">
						@for (item of navItems; track item) {
							<button
								type="menu"
								[attr.id]="'nav-' + item.key"
								(click)="showSubmenu(item.key)"
								class="inline-flex items-center pt-1 text-sm font-medium text-gray-700 border-b-4 border-transparent whitespace-nowrap dropbtn hover:border-uc-blue-900 hover:text-uc-blue-900 menu__tab-title"
							>
								{{ item.name }}
							</button>
						}
					</div>
				</div>
			}

			<!-- user avatar -->
			<div class="hidden lg:block">
				<uc-avatar-user-menu
					[userAuthenticated]="userAuthenticated"
					[avatar]="studentDetails()?.avatar"
					[clientAuthenticated]="clientAuthenticated"
					[logo]="clientDetails()?.logo"
				/>
			</div>

			<!-- mobile burger icon -->
			<div class="flex items-center ml-4 lg:hidden">
				<button
					type="menu"
					data-cy="burger-nav-button"
					(click)="showMobileMenu()"
					class="h-[42px] inline-flex items-center justify-center p-2.5 text-gray-400 bg-gray-200 rounded-md dropbtn hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-uc-blue-900"
					aria-expanded="false"
				>
					<span class="sr-only">
						{{ isMobileNavShowing ? 'Close' : 'Open' }} main menu
					</span>

					<!-- burger -->
					@if (!isMobileNavShowing) {
						<uc-burger-svg />
					}

					<!-- close -->
					@if (isMobileNavShowing) {
						<uc-close-modal-svg customStyles="!w-5 stroke-gray-900 show" />
					}
				</button>
			</div>
		</div>
	</div>
</nav>

<!-- DESKTOP: submenus -->
@if (!showMobile) {
	<div
		id="desktop-container"
		class="hidden overlay-navbar-panel lg:block"
		aria-labelledby="overlay-panel"
		[class.collapsed]="!isPanelVisible"
	>
		@if (isPanelVisible) {
			<div
				class="cursor-pointer overlay"
				aria-hidden="true"
				[@fadeInOut]
				(click)="closeNavbarPanel()"
			></div>
		}
		<div class="w-full">
			<uc-subnav-clearing class="out-of-sight submenu" id="clearing" />
			<uc-subnav-courses class="out-of-sight submenu" id="courses" />
			<uc-subnav-unis class="out-of-sight submenu" id="unis" />
			<uc-subnav-events class="out-of-sight submenu" id="events" />
			<uc-subnav-rankings class="out-of-sight submenu" id="rankings" />
			<uc-subnav-guides class="out-of-sight submenu" id="guides" />
			<uc-subnav-advice class="out-of-sight submenu" id="advice" />
			<uc-subnav-statements class="out-of-sight submenu" id="statements" />
		</div>
	</div>
}

<!-- MOBILE: expanded nav & submenus -->
@if (showMobile) {
	<div
		class="relative overflow-x-hidden overflow-y-scroll bg-transparent lg:hidden"
		id="mobile-parent"
	>
		<div class="flex flex-col invisible pb-28" id="mobile-nav">
			@for (item of navItems; track item) {
				<div>
					<!-- button to expand nav -->
					<button
						type="menu"
						[attr.id]="'nav-' + item.key"
						(click)="showSubmenu('mobile' + item.key)"
						class="flex items-center justify-between w-full px-5 py-4 text-lg font-medium text-gray-900 lg:py-4"
						[ngClass]="{
							'bg-gray-50': expandedNav === 'mobile' + item.key,
						}"
					>
						{{ item.name }}
						@if (expandedNav !== 'mobile' + item.key) {
							<uc-arrow-down-svg class="w-4" />
						}
						@if (expandedNav === 'mobile' + item.key) {
							<uc-nav-menu-up-arrow-svg />
						}
					</button>
					<!-- divider -->
					<div class="px-5" aria-hidden="true">
						<div class="w-full h-px border-b"></div>
					</div>
					<!-- submenus-->
					<div
						class="px-5 mt-4 out-of-sight submenu"
						[attr.id]="'mobile' + item.key"
					>
						<uc-mobile-subnav-container [section]="item.key" />
					</div>
				</div>
			}
			<!-- MOBILE secondary nav inc sign in & edit profile -->
			<uc-secondary-nav-mobile
				[userAuthenticated]="userAuthenticated"
				[clientAuthenticated]="clientAuthenticated"
				[avatar]="studentDetails()?.avatar"
				[firstName]="studentDetails()?.first_name"
				[secondName]="studentDetails()?.last_name"
				[startYear]="studentDetails()?.preferences?.start_year"
				[degree]="studentDetails()?.preferences?.qualification"
				[logo]="clientDetails()?.logo"
				[uniId]="clientDetails()?.id"
				[uniName]="clientDetails()?.name"
			/>
		</div>
	</div>
}

<uc-modal />
@if (showTickertape) {
	<uc-tickertape />
}
