export const COURSES_NAVIGATION = {
	hubLinks: {
		undergraduate: {
			title: 'Undergraduate Courses',
			links: [
				{
					title: 'Bachelors degrees',
					description: 'Find all degrees for an A-Z of subjects.',
					value: '/courses/degrees',
				},
				{
					title: 'Foundation Degrees',
					description: 'Find foundations country-wide.',
					value: '/courses/degrees/foundation',
				},
				{
					title: 'Undergraduate Certificates & Diplomas',
					description: 'HNDs, HNCs and more.',
					value: '/courses/degrees/undergraduate-certificate-diplomas',
				},
			],
		},
		postgraduate: {
			title: 'Postgraduate Courses',
			links: [
				{
					title: 'Master’s Degree Programmes',
					description: 'Search for your next step.',
					value: '/courses/degrees/masters',
				},
				{
					title: 'PhD Programmes',
					description: 'Find universities offering PhD courses.',
					value: '/courses/degrees/phd',
				},
				{
					title: 'Postgraduate Certificates & Diplomas',
					description: 'Level up with postgrad course options.',
					value: '/courses/degrees/postgraduate-certificate-diplomas',
				},
			],
		},
	},
	mainLinks: {
		title: 'Not sure where to start?',
		undergraduate: {
			linkText: 'Search now',
			title: 'Undergraduate Courses',
			href: '/courses/degrees/undergraduate',
			description: 'Bachelor’s, HND, HNC and so many more options to explore.',
			image: '/assets/img/Course_Search_UG_Nav.png',
			external: false,
		},
		postgraduate: {
			linkText: 'Search now',
			title: 'Postgraduate Courses',
			href: '/courses/degrees/postgraduate',
			description:
				'Start your search here with all postgraduate options to discover.',
			image: '/assets/img/Course_Search_PG_Nav.png',
			external: false,
		},
	},
};
