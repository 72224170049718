import { ChangeDetectionStrategy, Component } from '@angular/core';
import { COURSES_NAVIGATION } from '../../../static/subnav-courses.properties';

@Component({
	selector: 'uc-subnav-courses',
	templateUrl: './subnav-courses.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavCoursesComponent {
	data = { ...COURSES_NAVIGATION };
}
